import { TYPE } from "vue-toastification";
import { apiCall, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";
import { router } from "@/core/router";
import i18n from "@/core/i18n/i18n";

const { t } = i18n.global;
export async function getConfigurationSettings(type: string, ownerId?: string): Promise<any> {
  let endPoint = `configs/Root/${type}`;
  if (type === "TENANT" && ownerId) {
    endPoint += `/${ownerId}`;
  }
  if (type === "ORGANIZATION" && ownerId) {
    endPoint += `/${ownerId}`;
  }

  try {
    const response = await apiCall("GET", endPoint);
    if (response.code === 200) {
      return response.result;
    }
    logResponse(response);
    return undefined;
  } catch (error) {
    console.error("API Error:", error);
    showAlert(TYPE.WARNING, t("ConfigurationSettings.SettingsFetchError"));
    return undefined;
  }
}

export async function updateConfiguraitonSettings(data: any): Promise<any> {
  const endPoint = "configs";
  const response = await apiCall("POST", endPoint, data);
  logResponse(response);
  return response;
}

export async function getExecutionProperties(isTenant: boolean): Promise<any> {
  const endpoint = isTenant
    ? "/properties/camel-gateway/docker/latest/tenant"
    : "/properties/camel-gateway/docker/latest/organization";
  try {
    const response = await apiCall("GET", endpoint);
    if (response.type === "SUCCESS" && response.code === 200 && Array.isArray(response.result)) {
      const resultItem = response.result[0];
      const parsedValue = JSON.parse(resultItem.value);

      return {
        deleted: resultItem.deleted,
        key: resultItem.key,
        value: parsedValue.defaultValue || resultItem.value,
        defaultValue: parsedValue.defaultValue || resultItem.value,
        enumerationValues: parsedValue.enumerationValues || [],
        description: resultItem.description,
      };
    }
    logResponse(response);
    return undefined;
  } catch (error) {
    console.error("API Error:", error);
    showAlert(TYPE.WARNING, t("Flow.FetchError"));
    return undefined;
  }
}
